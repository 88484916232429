import React, { useState } from 'react'
import { Footer } from './Footer'
import { Header } from './Header'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../config';
import { useEffect } from 'react';
// import parse from 'html-react-parser';

    


export const Singleproject = () => {

    const location = useLocation();

    console.log(location)

    const { category,image } = location?.state

    useEffect(() => {
        getdata();
    }, [])

        const UP = ()=>{
            
            window.scrollTo(0, 0)
                
        }

    const [product, setProduct] = useState();
    const { productname } = useParams();

    const getdata = async () => {

        const { data } = await axiosInstance.get(`/api/product/singleproject/${productname}`);
        console.log("productSingle", data);
        setProduct(data);

    }
    return (
        <div>


            <Header />

            {/* <section className="product_banner d-flex justify-content-start align-items-center " style={{backgroundImage: `url(${require(`../../../api/uploadFiles/${product?.productImage}`)})`}}> */}
            <section id="up" className="product_banner d-flex justify-content-start align-items-center " style={{ backgroundSize:'cover',backgroundPosition:'center', backgroundImage: `url(${require(`./images/${image}`)})`}}>
                <div className="text-center text-white px-5">
                    <h1 style={{ fontSize: '4rem' }}><b>{category}</b></h1>
                </div>
            </section>







 {/* Latest Products---- */}
           {/* Latest Products---- */}
           <section className="category mt-5 py-5">
                <div className="container">
                    <div className="text-center">
                        <h2><b>Our Products</b></h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box1 d-flex justify-content-center align-items-center rounded ">
                                    <h3 className="text-white"><b>Cotton Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around  py-4">
                                    <Link to={`/singleproject`} onClick={UP}  className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Cotton Fabrics", image: "cot2.jpg" }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} ><b>Call Now</b></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3 ">
                            <div className="p-0 rounded border">
                                <div className="category_box2 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Linen Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} onClick={UP} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Linen fabrics", image: "lin3.jpg" }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box3 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Khadi Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} onClick={UP} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Khadi Fabrics", image: 'khad3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box4 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Poplin Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} onClick={UP} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Poplin", image: 'pop3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="p-0 rounded border">
                                <div className="category_box5 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Polyster Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} onClick={UP} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Polyester", image: 'poly3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box6 d-flex justify-content-center align-items-center rounded">

                                    <h3 className="text-white"><b>Cotton Linen Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} onClick={UP} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Cotton linen", image: 'cotl3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Latest Products---- */}
            {/* Latest Products---- */}

            {/* <div className="container-fluid">
                <div className="row p-5">
                    <div className="col-lg-6">
                        <div className="text-start">
                            <div className="py-2">
                                <h5 className="text-muted"> Client</h5>
                                <h6>{product?.ProductClient}</h6>
                            </div>
                            <div className="py-2">
                                <h5 className="text-muted"> Location</h5>
                                <h6>{product?.ProductLocation}</h6>
                            </div>
                            <div className="py-2">
                                <h5 className="text-muted"> Value</h5>
                                <h6>{product?.ProductPrice}</h6>
                            </div>
                            <div className="py-2">
                                <h5 className="text-muted"> Size</h5>
                                <h6>{product?.ProductSize}</h6>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="text-start">
                            {parse(`${product?.ProductDescription}`)}

                        </div>
                    </div>
                </div>
            </div> */}


            <div className="container mt-5 ">
               <div className="row py-5">
               <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src={require('./images/aboutbanner.jpg')} class="d-block w-100 img-fluid" style={{height:'70vh'}} alt="..."/>
                        </div>
                        <div class="carousel-item">
                            <img src={require('./images/nslider1.jpg')} class="d-block w-100 img-fluid" style={{height:'70vh'}} alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={require('./images/nslider2.jpg')} class="d-block w-100 img-fluid" style={{height:'70vh'}} alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={require('./images/nslider3.jpg')} class="d-block w-100 img-fluid" style={{height:'70vh'}} alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
               </div>
            </div>




           


            <Footer />



        </div>
    )
}
