export default [
    {
        Id: 1,
        imgsrc: require(`../component/images/infra/gall1.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 2,
        imgsrc: require(`../component/images/infra/gall2.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 3,
        imgsrc: require(`../component/images/infra/gall3.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 4,
        imgsrc: require(`../component/images/infra/gall4.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 5,
        imgsrc: require(`../component/images/infra/gall5.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 6,
        imgsrc: require(`../component/images/infra/gall19.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 7,
        imgsrc: require(`../component/images/infra/gall7.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 8,
        imgsrc: require(`../component/images/infra/gall8.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 9,
        imgsrc: require(`../component/images/infra/gall9.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 10,
        imgsrc: require(`../component/images/infra/gall10.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 11,
        imgsrc: require(`../component/images/infra/gall16.jpg`),
        // spinner:"text-primary",
    },
    {
        Id: 12,
        imgsrc: require(`../component/images/infra/gall14.jpg`),
        // spinner:"text-primary",
    },
    

];
