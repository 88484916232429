import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import emailjs from 'emailjs-com';
import NumberScroller from 'react-number-scroller';
import axios from 'axios';

import { Footer } from './Footer'
import { Header } from './Header';

export const Home = () => {

    const [number, setNumber] = useState(400);
    // useEffect(() => {
    //     setNumber(1000)
    // }, [])
    const [lastname, setLastName] = useState();
    const [name, setName] = useState();
    const [phone, setphone] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        alert("form submitted")
        axios.get(`http://api.digisidekick.com/api/Mail?Email=care@mahajantextiles.com&Firstname=${name}&Lastname= " "&Number=${phone}&Usermial=${email}&UserMessage=${message}&other=null`,)
            .then((res) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(res.data)

            }).catch((error) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(error)
            });
        setName("");
        setLastName("");
        setphone('');
        setEmail("")
        setMessage("");
        

       

        // history.push("/thank")
    }


    const btnn = () => {
        // e.persist();
        let btn = document.querySelector('.menu_btn');
        let navbar = document.querySelector('.nav_icon');
        // document.querySelector('.menu_btn').onclick = function () {
        navbar.classList.toggle('mobile');
        btn.classList.toggle('fa-times');
        // }
    }
    useEffect(() => {

        var btndesing = document.getElementById("parallax");
        window.addEventListener("scroll", function () {
            var logic = window.pageYOffset * 0.03;
            btndesing.style.backgroundPositionY = +logic + "px";
        })
    }, [])

    useEffect(() => {
        //    alert('show')
        // const textForStorage = 'Hello World.';
        // sessionStorage.setItem('my-key', textForStorage);

        // const textFromStorage = sessionStorage.getItem('my-key');

        //        alert(textFromStorage)




        setTimeout(function () {
            document.querySelector('.preloader').style.transition = "all 0.8s ease"
            document.querySelector('.preloader').style.opacity = "0"
            document.querySelector('.preloader').style.visibility = "hidden"
        }, 3000);


        // alert(textFromStorage)


    }, [])

    //  <!-- SLick Effect Onscroll -->


    return (

        <div>


            {/* preloader--- */}
            <div className="preloader row justify-content-center align-items-center" style={{ height: '100vh', width: '100%', zIndex: '4', top: '0', left: '11px', backgroundColor: '#000', position: 'fixed' }} >
                <div className="text-center">
                    <h1 className="" style={{ fontSize: '5.5rem' }}>

                        <span>M</span>
                        <span>A</span>
                        <span>H</span>
                        <span>A</span>
                        <span>J</span>
                        <span>A</span>
                        <span>N</span>

                    </h1>
                </div>
            </div>
            {/* preloader--- */}


            {/* <!-- <Header Start============ --> */}
            <Header />

            <section className="">

                {/* <!-- <Header End============= --> */}
                <section className="banner" style={{ width: '100%' }}>
                    <div style={{ height: '100vh', position: 'relative' }}>
                        <video id="homepage_video" autoPlay loop muted playsinline width="100%" class="uk-cover"
                            style={{ objectFit: 'cover', minHeight: '100vh' }}>
                            <source type="video/mp4" data-id="1920" itemprop="embedUrl" src={require('./images/infra/mahavidei.mp4')} />
                        </video>
                    </div>
                    <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: '1', background: 'rgba(0,0,0,0.2)' }} className="px-3 text-start d-flex justify-content-center justify-content-md-start align-items-center text-white">
                        <h1 id="parallax " style={{ fontSize: '4.5rem' }}>
                            <b>Mahajan</b><br/>
                            <b style={{ fontSize: '3.5rem' }}>GROUP OF TEXTILES</b>
                            <p>
                                A homegrown brand to serve the High-Quality Fabric needs of India.
                            </p>
                        </h1>
                    </div>

                </section>
                {/* <section className="banner" style={{ marginTop: "60px", width: '100%' }}>
                    <div className="text-center text_box_banner p-5 m-2">
                        <h1><b>Unstiched Suits</b></h1>
                        <p>
                            <b>Exclusive & Unique Patterns</b>
                        </p>
                        <a href="#" className="btn btn-dark text-white"> SHOP NOW</a>
                    </div>

                </section> */}
            </section>

            {/* Quality---- */}
            <section className="quality mt-5">
                <div className="container d-flex justify-content-center align-items-center">
                    <div className="text-center col-lg-8">
                        <h2><b>Welcome To Mahajan Group of Textiles </b></h2>
                        <p>Situated in India for India, providing you with Top quality fabric to fulfil your Aesthetics need in traditional and cultural styles.

                            Premium Quality Fabrics
                            Get the Most Exclusive and Unique Quality Fabric to make your products irresistible
                            Multiple Categories</p>
                    </div>
                </div>

                <div className="container-fluid py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 py-3">
                            <div className="quaitybg9 ">
                                <div className="text-end p-5 innerqualityup9" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                                    <h4 className="text-muted">Our Branch </h4>
                                    <h2 className="text-white "><b>VIJAYA TEXTILES</b></h2>
                                    <h2 className="text-white "><b>LAXMI TEXTILES</b></h2>
                                    <h2 className="text-white "><b>MAHAJAN TEXTILES</b></h2>
                                    {/* <a href="#"><i class="fa-solid fa-up-long text-white" style={{ fontSize: '2rem' }}></i></a> */}
                                </div>
                                <div className="innerquality9 d-flex justify-content-center align-items-center">
                                    <div className="qualitytextbox text-white text-center px-5">
                                        <h4>Get the Most Exclusive and Unique Quality Fabric to make your products irresistible</h4>

                                        <a href="tel:8328029263" style={{ color: '#fff', position: 'relative', zIndex: '1' }} className="btn bg-white text-dark"><b>Order Now</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 py-3">
                            <div className="quaitybg4 ">
                                <div className="text-end p-5 innerqualityup4" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                                    <h4 className="text-muted">Our Brand</h4>
                                    <h2 className="text-white mb-0"><b>JDMORGAAN</b></h2>
                                    <small className="text-white">Whales Linen</small>
                                </div>
                                <div className="innerquality4 d-flex justify-content-center align-items-center">
                                    <div className="qualitytextbox2 text-white text-center p-5 mx-5" style={{ backgroundColor: '#fffce6', opacity: '0.6' }}>
                                        <h2><b>Get multiple options of Fabric to fit your diverse products needs</b></h2>
                                        <a href="http://jdmorgaan.com/" target="_blank" style={{ backgroundColor: '#b06033', color: '#fff', position: 'relative', zIndex: '1' }} className="btn text-white"><b>Visit Now</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        {/* <div className="col-lg-6 py-3">
                            <div className="quaitybg77 ">
                                <div className="text-end p-5 innerqualityup77" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                                    <h4 className="text-muted">Our Branch </h4>
                                    <h2 className="text-white "><b>LAXMI TEXTILES</b></h2>
                                </div>
                                <div className="innerquality77 d-flex justify-content-center align-items-center">
                                    <div className="qualitytextbox text-white text-center px-5">
                                        <h4>Get the Most Exclusive and Unique Quality Fabric to make your products irresistible</h4>

                                        <a href="tel:8328029263" style={{ color: '#fff', position: 'relative', zIndex: '1' }} className="btn bg-white text-dark"><b>Order Now</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 py-3">
                            <div className="quaitybg88 ">
                                <div className="text-end p-5 innerqualityup88" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                                    <h4 className="text-muted">Our Branch </h4>
                                    <h2 className="text-white "><b>MAHAJAN TEXTILES</b></h2>
                                </div>
                                <div className="innerquality88 d-flex justify-content-center align-items-center">
                                    <div className="qualitytextbox text-white text-center px-5">
                                        <h4>Get the Most Exclusive and Unique Quality Fabric to make your products irresistible</h4>

                                        <a href="tel:8328029263" style={{ color: '#fff', position: 'relative', zIndex: '1' }} className="btn bg-white text-dark"><b>Order Now</b></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>
            {/* Quality---- */}

            {/* Our Story---- */}
            <section className="story my-5 py-5" >
                <div className="story_box py-5 d-flex align-items-center" style={{ backgroundColor: '#fffce6', height: '80vh' }}>
                    <div className="col-lg-4 p-2 p-lg-5 text-start " style={{ backgroundColor: 'rgba(0,0,0,0.8)', color: '#fff' }}>
                        <h3>Our Story</h3>
                        <p>
                            Incorporated in the year 2002 we at Mahajan Group of Textiles have trained more than 1000 people and generated an equivalent amount of employment opportunities for rural as well as urban India and are currently present in over 400 towns in over 150 cities across India.
                        </p>
                        <Link to="/about" style={{ backgroundColor: '#fff', color: '#000' }} className="btn"><b>Read Our Story</b></Link>
                    </div>
                </div>
            </section>
            {/* Our Story---- */}

            {/* Category---- */}
            {/* <section className="category mt-5">
                <div className="container-fluid">
                    <div className="text-center">
                        <h2><b>Featured Category</b></h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 py-2">
                            <div className="category_box7 d-flex justify-content-center align-items-center">
                                <Link href={`/product`} className="text-center btn text-dark" style={{ cursor: 'pointer', backgroundColor: '#fffce6', color: '#fff' }} to={`/product`} state={{ category: "Poplin", image: 'pop1.jpg' }} ><b>Poplin Fabrics</b></Link>
                            </div>
                        </div>
                        <div className="col-lg-4 py-2">
                            <div className="category_box8 d-flex justify-content-center align-items-center">
                                <Link href={`/product`} className="text-center btn text-dark" style={{ cursor: 'pointer', backgroundColor: '#fffce6', color: '#fff' }} to={`/product`} state={{ category: "Polyester", image: 'poly1.jpg' }} ><b>Polyester</b></Link>
                            </div>
                        </div>
                        <div className="col-lg-4 py-2">
                            <div className="category_box9 d-flex justify-content-center align-items-center">
                                <Link href={`/product`} className="text-center btn text-dark" style={{ cursor: 'pointer', backgroundColor: '#fffce6', color: '#fff' }} to={`/product`} state={{ category: "Cotton linen", image: 'cotl1.jpg' }} ><b>Cotton Linen</b></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* Category---- */}


            {/* Latest Products---- */}
            <section className="category mt-5 py-5">
                <div className="container">
                    <div className="text-center">
                        <h2><b>Our Products</b></h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box1 d-flex justify-content-center align-items-center rounded ">
                                    <h3 className="text-white"><b>Cotton Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around  py-4">
                                    <Link to={`/singleproject`} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Cotton Fabrics", image: "cot2.jpg" }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3 ">
                            <div className="p-0 rounded border">
                                <div className="category_box2 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Linen Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Linen fabrics", image: "lin3.jpg" }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box3 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Khadi Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Khadi Fabrics", image: 'khad3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box4 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Poplin Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Poplin", image: 'pop3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="p-0 rounded border">
                                <div className="category_box5 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Polyster Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Polyester", image: 'poly3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box6 d-flex justify-content-center align-items-center rounded">

                                    <h3 className="text-white"><b>Cotton Linen Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Cotton linen", image: 'cotl3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Latest Products---- */}




            <div class="cta1 py-5 container-fluid my-5" >
                <div class="text-center text-white  row justify-content-center align-items-center">
                    <div className="col-lg-6">
                        <h4>For more information about our products <b> Call Now!</b></h4>
                    </div>
                </div>
                <div class="text-center d-flex row col-lg-4 mx-auto justify-content-around">
                    <div class="col-lg-6 col-6 px-1">
                        <Link to="/contact-us" class="btn btn-dark text-white">
                            <i class="fas fa-rocket" ></i>&nbsp; Contact Us </Link><br />
                    </div>
                    <div class="col-lg-6 col-6 px-1">
                        <a href="tel:8328029263 px-3" class="btn" style={{ backgroundColor: '#fff', color: '#222' }}>
                            <i class="fas fa-phone-alt text-dark" style={{ color: '#fff' }}></i>+91-8328029263</a>
                    </div>
                </div>
            </div>


            {/* Our Achivement------- */}
            {/* <section id="counts" class="counts py-3 ">
                <div class="container">

                    <div class="row align-items-center  counters">

                        <div class="col-lg-6">

                            <div>
                                <h3 class="text-dark"><b>Our Achievements</b></h3>
                                <p>
                                    Through our high-quality integration solutions and consistent efforts spanning over the past 3 decades, we have emerged as the market leader in this segment. We are proud to have the utmost trust of our customers through the virtue of our innovative designs and our market reputation. We have contributed and served towards
                                </p>
                            </div>

                        </div>
                        <div class="col-lg-6 py-5">
                            <div class="row py-0">
                                <div class="col-lg-6 col-6 p-1 text-center ">
                                    <div class="p-3  rounded" style={{ height: '210px', backgroundColor: '#af6033' }}>
                                        <p class="mb-0 " style={{ fontSize: '1rem' }}><i class="fas fa-pencil-ruler text-white rounded-circle  border  border-warning p-3"></i></p>
                                        <span><NumberScroller from={100} to={1200} timeout={1000} /></span>&nbsp;<span>+</span>
                                        <p className="text-white">Clients</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-6 p-1 text-center">
                                    <div class="p-3  rounded " style={{ height: '210px', backgroundColor: '#af6033' }}>
                                        <p class="mb-0 " style={{ fontSize: '1rem' }}><i class="fas fa-project-diagram text-white rounded-circle  border border-warning p-3"></i></p>
                                        <span><NumberScroller from={0} to={20} timeout={1000} /></span>&nbsp;<span>+</span>
                                        <p className="text-white">Years Of Experience</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-6 p-1 text-center">
                                    <div class="p-3  rounded" style={{ height: '210px', backgroundColor: '#af6033' }}>
                                        <p class="mb-0 " style={{ fontSize: '1rem' }}><i class="fas fa-smile text-white rounded-circle  border border-warning p-3"></i></p>
                                        <span><NumberScroller from={0} to={99.9} decimalPlaces={2} timeout={1000} /></span>&nbsp;<span>%</span>
                                        <p className="text-white">Clients Satisfaction</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-6 p-1 text-center">
                                    <div class="p-3  rounded" style={{ height: '210px', backgroundColor: '#af6033' }}>
                                        <p class="mb-0 " style={{ fontSize: '1rem' }}><i class="fas fa-box text-white rounded-circle  border  border-warning p-3"></i></p>
                                        <span><NumberScroller from={0} to={700} timeout={1000} /></span>&nbsp;<span>+</span>
                                        <p className="text-white">Products </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </section> */}
            {/* Our Achivement------- */}

            {/* Reviews----- */}
            {/* <!--Start What-Customer-Say Section--> */}

            {/* //   <!--End What-Customer-Say Section--> */}
            {/* Reviews----- */}

            <div className="aboutus py-lg-5 py-0 my-5">
                <div className="container py-lg-5 py-0">
                    <div className="row justify-contet-between ">
                        <div className="col-lg-4  right_about" >

                        </div>
                        <div className="col-lg-7 left_about">
                            <div className="ms-5 d-lg-block  d-none">
                                <h3>
                                We are proud to have the utmost trust of our customers through the virtue of our innovative designs and our market reputation. We have contributed and served towards.
                                </h3>
                                <a href="#" className="btn btn-dark text-white px-5">know more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Form--- */}
            <section class="cta py-5">
                <div class="container mx-auto">
                    <div class="row py-5">
                        <div class="col-lg-5 col-12 text-start text-white">
                            <h2><b>Request a Free <br /> Call Back</b>
                            </h2>
                            <small>Know more and place your order for Premium and High-Quality Fabrics</small>
                        </div>
                        <div class="col-lg-7 col-12">
                            <form action=""  >
                                <div class="row g-3   align-items-center">
                                    <div class="col-lg-5 col-12">
                                        <input type="text" id="inputPassword6" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" name="f_name" class="form-control" aria-describedby="passwordHelpInline" />
                                    </div><div class="col-lg-5 col-12">
                                        <input type="text" id="inputPassword6" value={phone} onChange={(e) => setphone(e.target.value)} placeholder="Contact" name="number" class="form-control" aria-describedby="passwordHelpInline" />
                                    </div><div class="col-lg-5 col-12">
                                        <input type="mail" id="inputPassword6" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" name="email" class="form-control" aria-describedby="passwordHelpInline" />
                                    </div>
                                    <div class="col-lg-5 col-12">
                                        <input type="text" id="inputPassword6" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message" name="message" class="form-control" aria-describedby="passwordHelpInline" />
                                    </div>
                                </div>
                                <div class="text-lg-start text-center py-3 ">
                                    <input type="submit" onClick={handleSubmit}  class="btn text-dark px-5 py-2 bg-white" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* Form--- */}

            <Footer />

        </div>

    )
}
