import React, { useState } from 'react'
import { Footer } from './Footer'
import { Header } from './Header'
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../config';
import { useEffect } from 'react';
import parse from 'html-react-parser';


export const Singleservice = () => {

    useEffect(() => {
        getdata();
    }, [])

    const [service, setService] = useState();
    const { servicename } = useParams();

console.log("servicename",servicename)
    const getdata = async () => {

        const { data } = await axiosInstance.get(`/api/service/singleservice/${servicename}`);
        console.log("serviceSingle", data);
        setService(data);

    }

//     const mystyle = {
//     backgroundImage: `url("../../" + ${service?.ServiceImage})`,
//     height: '100vh',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
// };
// console.log("im",service?.ServiceImage)

return (
    <div>

        <Header />


    
        <section className="service_banner d-flex justify-content-start align-items-center " style={{  backgroundImage: "url(" + `http://tudorarchdesign.com/uploadFiles/${service?.ServiceImage}` + ")"}}>
        {/* <section className="service_banner d-flex justify-content-start align-items-center " style={{backgroundImage: `http://localhost:7000/uploadFiles/${service?.ServiceImage})`}}> */}
            <div className="text-center text-white px-5">
                <h1 style={{ fontSize: '4rem' }}><b>{service?.ServiceName}</b></h1>
            </div>
        </section>



      <div className="container-fluid">
      <div className="row p-5 ">
            <div className="col-lg-6 col-12">
                <div className="text-start">
                    <div className="py-2">
                        <h5 className="text-muted">Service Heading</h5>
                        <h6>{service?.ServiceHeading}</h6>
                    </div>
                    <div className="py-2">
                        <h5 className="text-muted">Service Status</h5>
                        <h6>{service?.ServiceStatus}</h6>
                    </div>
                    <div className="py-2">
                        <h5 className="text-muted">Service Value</h5>
                        <h6>{service?.ServicePrice}</h6>
                    </div>
                    
                </div>
            </div>
            <div className="col-lg-6 col-12">
                <div className="text-start">
                    {parse(`${service?.ServiceDescription}`)}

                </div>
            </div>
        </div>
      </div>




        <Footer />



    </div>
)
}
