import React from 'react'
import Gallery from '../JsonFolder/Gallery'
import { Footer } from './Footer'
import { Header } from './Header'

export const Photos = () => {
    return (
        <div>

            <section className="contact_banner2 d-flex justify-content-start align-items-center " style={{ height: '100vh' }}>
                <div className="text-center text-white px-5">
                    <h1 style={{ fontSize: '4rem' }}><b>Gallery</b></h1>
                    <a href="#" className="btn bg-white "><b>Know More</b></a>
                </div>
            </section>
            <Header />



            <section className="gallery">
                <div class="container photos_container">
                    <div class="gallery d-flex flex-wrap align-items-center justify-content-center">
                        {
                            Gallery.map((item) => (

                                <>
                                    <a href={item.imgsrc} data-lightbox="models" data-title="Caption1">
                                        <img src={item.imgsrc} style={{height:'300px',width:'300px'}} />
                                    </a>

                                </>

                            ))
                        }


                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}
