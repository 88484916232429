import { BrowserRouter, Navigate, Route, Routes, } from 'react-router-dom'
import "./component/Home.css";
import { UserDashboard } from './adminComponent/AdminUser/UserDashboard'
import { AddUser } from './adminComponent/AdminUser/AddUser'
import { Edituser } from './adminComponent/AdminUser/Edituser'

import { ProductDashboard } from './adminComponent/AdminProduct/ProductDashboard'
import { AddProduct } from './adminComponent/AdminProduct/AddProduct'
import { EditProduct } from './adminComponent/AdminProduct/EditProduct'

import { ServiceDashboard } from './adminComponent/AdminService/ServiceDashboard'
import { AddService } from './adminComponent/AdminService/AddService'
import { EditService } from './adminComponent/AdminService/EditService'

import { Home } from './component/Home';
import { Photos } from './component/Photos';
import { About } from './component/About';
import { Project } from './component/Project';
import { Conactus } from './component/Conactus';
import { Login } from './component/Login';
import { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import { Header } from './component/Header';
import { Service } from './component/Service';
// import { Singleproject } from './component/Singleproject';
import { Singleservice } from './component/Singleservice';
import { Singleproject } from './component/Singleproject';
import { Reviews } from './component/Reviews';
import { Reviews_Dashboard } from './adminComponent/Reviews/Reviews_Dashboard';
import { Add_Reviews } from './adminComponent/Reviews/Add_Reviews';
import { Edit_reviews } from './adminComponent/Reviews/Edit_reviews';


function App() {

  const [isadmin, setIsAdmin] = useState(true);

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      setIsAdmin(false)
    } else {
      const token = localStorage.getItem("token")
      const decoded = jwt_decode(token);
      const { isAdmin } = decoded
      console.log("isAdmin",isAdmin)
      if (isAdmin) {
        setIsAdmin(true)
      }
      else {
        setIsAdmin(false)
      }
    }

  }, [])

  

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/gallery' element={< Photos />} />
          <Route path='/singleservice/:servicename' element={<Singleservice />} />
          <Route path='/singleproject' element={<Singleproject />} />
          <Route path='/service' element={<Service />} />
          <Route path='/login' element={<Login />} />
          <Route path='/contact-us' element={<Conactus />} />
          <Route path='/product' element={<Project />} />
          <Route path='/reviews' element={<Reviews />} />


          {
            isadmin && (
              <>

                {/* Admin---- */}
                <Route path='/admin/user' element={<UserDashboard />} />
                <Route path='/admin/add-user' element={<AddUser />} />
                <Route path='/admin/edit-user/:id' element={<Edituser />} />

                <Route path='/admin/product' element={<ProductDashboard />} />
                <Route path='/admin/add-product' element={<AddProduct />} />
                <Route path='/admin/edit-product/:id' element={<EditProduct />} />

                <Route path='/admin/service' element={<ServiceDashboard />} />
                <Route path='/admin/add-service' element={<AddService />} />
                <Route path='/admin/edit-service/:id' element={<EditService />} />

                <Route path='/admin/review' element={<Reviews_Dashboard />} />
                <Route path='/admin/add-review' element={<Add_Reviews />} />
                <Route path='/admin/edit-review/:id' element={<Edit_reviews />} />
              </>
            )
          }
          <Route path="*" element={<Navigate to="/" />} />


          {/* Admin---- */}

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
