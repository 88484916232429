import React, { useRef } from 'react'
import emailjs from 'emailjs-com';
import { Footer } from './Footer'
import { Header } from './Header'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';


export const Conactus = () => {

    const [lastname, setLastName] = useState();
    const [name, setName] = useState();
    const [phone, setphone] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        alert("form submitted")
        axios.get(`http://api.digisidekick.com/api/Mail?Email=care@mahajantextiles.com&Firstname=${name}&Lastname= ${lastname}&Number=${phone}&Usermial=${email}&UserMessage=${message}&other=null`,)
            .then((res) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(res.data)

            }).catch((error) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(error)
            });
        setName("");
        setLastName("");
        setphone('');
        setEmail("")
        setMessage("");
        

       

        // history.push("/thank")
    }

    return (
        <div>


            <section className="contact_banner d-flex justify-content-start align-items-center " style={{ height: '100vh' }}>
                <div className="text-center text-white px-5">
                    <h1 style={{ fontSize: '4rem' }}><b>Contact Us</b></h1>
                </div>
            </section>
            <Header />





            <section className="form py-5">
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-6 pt-5 border" style={{ backgroundColor: '#fff', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                            <div>
                                <form  >
                                    <div class="row py-2">
                                        <div class="col">
                                            <label for="exampleFormControlInput1" class="form-label">First Name</label>
                                            <input class="form-control" type="text" placeholder="First Name" value={name} onChange={(e) => setName(e.target.value)} name="f_name" id="f_name" aria-label="default input example" />
                                        </div>
                                        <div class="col">
                                            <label for="exampleFormControlInput1" class="form-label">Last Name</label>
                                            <input class="form-control" type="text" placeholder="Last Name"  value={lastname} onChange={(e) => setLastName(e.target.value)} name="l_name" id="l_name" aria-label="default input example" />
                                        </div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col">
                                            <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
                                            <input class="form-control" type="text" placeholder="Phone Number" value={phone} onChange={(e) => setphone(e.target.value)} name="number" id="number" aria-label="default input example" />
                                        </div>
                                        <div class="col">
                                            <label for="exampleFormControlInput1" class="form-label">Email</label>
                                            <input class="form-control" type="text" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} name="email" id="email" aria-label="default input example" />
                                        </div>
                                    </div>
                                    <div class="form-floating">
                                        {/* <label for="exampleFormControlInput1" class="form-label">Message</label> */}
                                        <textarea class="form-control" placeholder="Leave a Message here" value={message} onChange={(e) => setMessage(e.target.value)} style={{ height: '150px' }} id="floatingTextarea"></textarea>
                                        <label for="floatingTextarea" name="message" id="message">Type Your Message Here</label>
                                    </div>
                                    <div class="text-lg-start text-center py-3 ">
                                        <input type="submit" value="Send" onClick={handleSubmit}  class="btn btn-dark text-white px-5 py-2 col-12" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="contactside">
                                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img src={require('./images/infra/gall16.jpg')} style={{ height: '70vh' }} class="d-block w-100" alt="..." />
                                            <div class="carousel-caption d-none d-md-block">
                                                <h3><b>Mahajan Group of Textiles</b></h3>
                                                <p>Fabric that suits your Customer</p>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <img src={require('./images/lin5.jpg')} style={{ height: '70vh' }} class="d-block w-100" alt="..." />
                                            <div class="carousel-caption d-none d-md-block">
                                                <h3><b>Connect with Us</b></h3>
                                                <p>Get your favourite Fabric Delivered</p>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <img src={require('./images/infra/gall1.jpg')} style={{ height: '70vh' }} class="d-block w-100" alt="..." />
                                            <div class="carousel-caption d-none d-md-block">
                                                <h3><b>Get special Discounts</b></h3>
                                                <p>Order Now to get 20% + 10% off on your Purchase</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />


        </div >
    )
}
