import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../config';
import { useSelector } from "react-redux";
import { Footer } from './Footer';
import { Header } from './Header'
import { Link } from 'react-router-dom';

import img1 from './images/tudorarchshapes/arc2.png'
import img2 from './images/tudorarchshapes/arc3.png'
import img3 from './images/tudorarchshapes/arc4.png'
import img4 from './images/tudorarchshapes/arc5.png'
import img5 from './images/tudorarchshapes/arc6.png'

export const Project = () => {


  useEffect(() => {
    getdata();
  }, [])


  const [product, setProduct] = useState();
  const [visibleMenu, setVisibleMenu] = useState(); // <- HERE

  const getdata = async () => {

    const { data } = await axiosInstance.get("/api/product");
    console.log("projectPageData", data);
    setProduct(data);
    setVisibleMenu(data);


  }
  const filterItem = (catitem) => {

    const updatedItems = product.filter((item) => item.ProductCategory === catitem);
    console.log("updatedItems", updatedItems)
    setVisibleMenu(updatedItems);
    // alert(catitem)

  }



  const myArray = [img1, img2, img3, img4, img5, img1, img2, img3, img4, img5, img1, img2, img3, img4, img5, img2, img3, img4, img5, img1, img2, img3, img4, img5];


  return (
    <div>


      <Header />


      <section className="project row justify-content-between align-items-center container mx-auto" style={{ height: '80vh' }}>
        <div className="col-lg-6 text-dark text-start">
          <h1 style={{ fontSize: '4rem' }}><b>Products</b></h1>
          <p>
          Get the Most Exclusive and Unique Quality Fabric to make your products irresistible.
          </p>
        </div>
        <div className="col-lg-3 text-start">
          <ul class="nav nav-pills row flex column  mb-3" id="pills-tab" role="tablist">


            <b>Filter</b>
            <li>
              <div id="pills-cotton-tab" data-bs-toggle="pill" data-bs-target="#pills-cotton" type="button" role="tab" aria-controls="pills-cotton" aria-selected="true" style={{ cursor: 'pointer' }}>
                Cotton Fabrics
              </div>
            </li><br />
            <li>
              <div id="pills-linen-tab" data-bs-toggle="pill" data-bs-target="#pills-linen" type="button" role="tab" aria-controls="pills-linen" aria-selected="true" style={{ cursor: 'pointer' }} >
                Linen Fabrics
              </div>
            </li><br />
            <li>
              <div id="pills-khadi-tab" data-bs-toggle="pill" data-bs-target="#pills-khadi" type="button" role="tab" aria-controls="pills-khadi" aria-selected="true" style={{ cursor: 'pointer' }} >
                Khadi Fabrics
              </div>
            </li><br />
            <li>
              <div id="pills-poplin-tab" data-bs-toggle="pill" data-bs-target="#pills-poplin" type="button" role="tab" aria-controls="pills-poplin" aria-selected="true" style={{ cursor: 'pointer' }} >
                Poplin Fabrics
              </div>
            </li><br />
            <li>
              <div id="pills-polyster-tab" data-bs-toggle="pill" data-bs-target="#pills-polyster" type="button" role="tab" aria-controls="pills-polyster" aria-selected="true" style={{ cursor: 'pointer' }} >
                Polyster Fabrics
              </div>
            </li><br />
            <li>
              <div id="pills-cottonlinen-tab" data-bs-toggle="pill" data-bs-target="#pills-cottonlinen" type="button" role="tab" aria-controls="pills-cottonlinen" aria-selected="true" style={{ cursor: 'pointer' }} >
                Cotton Linen Fabrics
              </div>
            </li><br />

          </ul>
        </div>
      </section>


      <div className="container-fluid" >

        <div class="tab-content" id="pills-tabContent">
          {/* ---Cotton fabric--- */}
          <div class="tab-pane fade show active" id="pills-cotton" role="tabpanel" aria-labelledby="pills-cotton-tab">

            <div className="row py-5" id="down">

              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Fabrics", image: 'cot1.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cot1.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Fabrics", image: 'cot2.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cot2.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Fabrics", image: 'cot3.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cot3.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Fabrics", image: 'cot4.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cot4.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Fabrics", image: 'cot5.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cot5.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            </div>

          </div>{/* ---Cotton fabric--- */}

          {/* ---Linen fabric--- */}
          <div class="tab-pane fade" id="pills-linen" role="tabpanel" aria-labelledby="pills-linen-tab">
            <div className="row py-5" id="down">

              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Linen Fabrics", image: 'lin1.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/lin1.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Linen Fabrics", image: 'lin2.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/lin2.webp")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Linen Fabrics", image: 'lin3.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/lin3.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Linen Fabrics", image: 'lin4.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/lin4.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Linen Fabrics", image: 'lin5.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/lin5.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Linen Fabrics", image: 'lin6.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/lin6.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            </div>
          </div>{/* ---Linen fabric--- */}

          {/* ---khadi fabric--- */}
          <div class="tab-pane fade" id="pills-khadi" role="tabpanel" aria-labelledby="pills-khadi-tab">
            <div className="row py-5" id="down">

              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Khadi Fabrics", image: 'khad1.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/khad1.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Khadi fabric</h4>
                      <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Khadi fabric</h4>
                        <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Khadi Fabrics", image: 'khad2.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/khad2.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Khadi fabric</h4>
                      <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Khadi fabric</h4>
                        <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Khadi Fabrics", image: 'khad3.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/khad3.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Khadi fabric</h4>
                      <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Khadi fabric</h4>
                        <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Khadi Fabrics", image: 'khad4.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/khad4.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Khadi fabric</h4>
                      <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Khadi fabric</h4>
                        <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Khadi Fabrics", image: 'khad5.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/khad5.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Khadi fabric</h4>
                      <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Khadi fabric</h4>
                        <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Khadi Fabrics", image: 'khad6.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/khad6.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Khadi fabric</h4>
                      <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Khadi fabric</h4>
                        <h2 className="text-white text-muted"><b>Khadi fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            </div>
          </div>{/* ---khadi fabric--- */}

          {/* ---Poplin fabric--- */}
          <div class="tab-pane fade" id="pills-poplin" role="tabpanel" aria-labelledby="pills-poplin-tab">
            <div className="row py-5" id="down">

              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Poplin Fabrics", image: 'pop1.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/pop1.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Poplin fabric</h4>
                      <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Poplin fabric</h4>
                        <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Poplin Fabrics", image: 'pop2.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/pop2.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Poplin fabric</h4>
                      <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Poplin fabric</h4>
                        <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Poplin Fabrics", image: 'pop3.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/pop3.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Poplin fabric</h4>
                      <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Poplin fabric</h4>
                        <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Poplin Fabrics", image: 'pop4.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/pop4.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Poplin fabric</h4>
                      <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Poplin fabric</h4>
                        <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Poplin Fabrics", image: 'pop5.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/pop5.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Poplin fabric</h4>
                      <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Poplin fabric</h4>
                        <h2 className="text-white text-muted"><b>Poplin fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>


            </div>
          </div>{/* ---khadi fabric--- */}

          {/* ---Polyster fabric--- */}
          <div class="tab-pane fade" id="pills-polyster" role="tabpanel" aria-labelledby="pills-polyster-tab">
            <div className="row py-5" id="down">

              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Polyster Fabrics", image: 'poly1.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/poly1.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Polyster fabric</h4>
                      <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Polyster fabric</h4>
                        <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Polyster Fabrics", image: 'poly2.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/poly2.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Polyster fabric</h4>
                      <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Polyster fabric</h4>
                        <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Polyster Fabrics", image: 'poly3.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/poly3.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Polyster fabric</h4>
                      <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Polyster fabric</h4>
                        <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Polyster Fabrics", image: 'poly4.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/poly4.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Polyster fabric</h4>
                      <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Polyster fabric</h4>
                        <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Polyster Fabrics", image: 'poly5.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/poly5.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Polyster fabric</h4>
                      <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Polyster fabric</h4>
                        <h2 className="text-white text-muted"><b>Polyster fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>


            </div>
          </div>{/* ---Polyster fabric--- */}

          {/* ---Cotton-Linen fabric--- */}
          <div class="tab-pane fade" id="pills-cottonlinen" role="tabpanel" aria-labelledby="pills-cottonlinen-tab">
            <div className="row py-5" id="down">

              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Linen Fabrics", image: 'cotl1.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cotl1.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Linen Fabrics", image: 'cotl2.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cotl2.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Linen Fabrics", image: 'cotl3.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cotl3.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Linen Fabrics", image: 'cotl4.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cotl4.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 py-4">
                <Link to='/singleproject' state={{ category: "Cotton Linen Fabrics", image: 'cotton_linen.jpg' }}>
                  <div className="quaitybg3" style={{ backgroundImage: `url(${require("./images/cotton_linen.jpg")})` }}>
                    <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                      <h4 className="text-white">Cotton Linen fabric</h4>
                      <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                    </div>
                    <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                      <div>
                        <h4 className="text-white">Cotton Linen fabric</h4>
                        <h2 className="text-white text-muted"><b>Cotton Linen fabric</b></h2>
                        <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>


            </div>
          </div>{/* ---khadi fabric--- */}

        </div>


      </div>


      <Footer />

    </div>
  )
}
