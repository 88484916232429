import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../config';
// import bg from "../images/client/logohori.jpg"
// import bg from "../images/client/logohori.jpg"
// import { Link } from 'react-router-dom'
import img1 from "./images/tudorarchshapes/arc2.png"
import img2 from "./images/tudorarchshapes/arc3.png"
import img3 from "./images/tudorarchshapes/arc4.png"
import img4 from "./images/tudorarchshapes/arc5.png"
import img5 from "./images/tudorarchshapes/arc6.png"
import img6 from "./images/tudorarchshapes/arc3.png"
export const Header = () => {
    const [colorChange, setColorchange] = useState(false);
    const [productdata, setProductData] = useState();
    const [headerclose, setheaderclose] = useState(true);
    // console.log(productdata)
    // const res = [...new Set(productdata)];

    const newref = useRef();

    const changeNavbarColor = () => {
        let myhead = document.getElementById('myhead')
        let myimg = document.getElementById('myimg')

        if (window.scrollY >= 80) {
            setColorchange(true);
            myhead.style.backgroundColor = "#fff";
            myimg.src = "https://cdn.apt.london/img/shapes/black/apt-shapes-01.svg";
            myhead.style.transition = "all 0.8s  ease";

        }
        else {
            setColorchange(false);
            myimg.src = "https://cdn.apt.london/img/shapes/white/apt-shapes-01.svg";

            myhead.style.backgroundColor = "transparent"
        }
    };
    window.addEventListener('scroll', changeNavbarColor);
    useEffect(() => {

        window.scrollTo(0, 0)
        getdata();
        getcategorydata();


    }, [])

    const closemenu = (e) => {
        setheaderclose(false)
    }
    setTimeout(() => {
        setheaderclose(true)


    }, 100);




    //    console.log("shubha", productdata[0]._id)

    // get data First---
    const getdata = async () => {

        const { data } = await axiosInstance.get("/api/product");
        console.log("productdata", data);
        setProductData(data);

    }
    const getcategorydata = async () => {

        const { data } = await axiosInstance.get("/api/product/getcategory");

        const res = [...new Set(data.filter((o) => o.ProductCategory))];
        console.log('ressssss', res)
        setProductData(res);

    }

    const opentog = () => {
        let navcontrol = document.getElementById('navcontrol')
        let myhead = document.getElementById('myhead')
        let myimg = document.getElementById('myimg')
        if (navcontrol.style.opacity === "0") {
            navcontrol.style.visibility = "visible";
            myhead.style.backgroundColor = "transparent ";
            navcontrol.style.opacity = "1";
            navcontrol.style.transform = "scale(1)";
            myimg.src = "https://cdn.apt.london/img/shapes/white/apt-shapes-01.svg";
            navcontrol.style.transition = "all 0.8s  ease";
            myhead.style.transition = "all 0.8s  ease";
            myimg.style.transition = "all 0.8s  ease";
        } else {
            navcontrol.style.visibility = "hidden";
            myhead.style.backgroundColor = "transparent";
            navcontrol.style.opacity = "0";
            myimg.src = "https://cdn.apt.london/img/shapes/black/apt-shapes-01.svg";
            navcontrol.style.transform = "scale(0.0)";
            navcontrol.style.transition = "all 0.8s  ease";
        }

    }


    // const changeImg1 = ()=>{

    useEffect(() => {

        // let arrimg = ['./images/tudorarchshapes/arc2.png','./images/tudorarchshapes/arc3.png','./images/tudorarchshapes/arc4.png','./images/tudorarchshapes/arc5.png']
        // let list = document.querySelectorAll('.butn');
        // let bg = document.querySelector('#arc1');
        // let i=0;
        // list.forEach(elements => {
        //     i++;
        //     elements.addEventListener('mouseenter', function (event) {
        //         bg.src = arrimg[i];
        //         bg.style.opacity = "1";
        //         bg.style.transition = "all 0.5s ease";
        //     })
        //     elements.addEventListener('mouseleave', function (event) {
        //         bg.style.opacity = "0";
        //         bg.style.transition = "all 0.5s ease";
        //         bg.src = arrimg[i];
        //     })
        // })
    }, [])
    // let arc1 = document.getElementById('arc1');
    // arc1.style.opacity = "1";
    // arc1.style.transition = "all 0.8s  ease";

    // }


    const unhover = () => {
        let navcontrol = document.getElementById('navcontrol')
        navcontrol.style.backgroundColor = "#000000";
    }
    const unhover1 = () => {
        let btn = document.getElementById('arc1');
        btn.src = "";
        btn.style.transition = "all 0.8s  ease";
        btn.style.opacity = "0";
    }

    const btnn = () => {
        let btn = document.querySelector('.menu_btn');
        let navbar = document.querySelector('.oldnav');
        navbar.classList.toggle('mobile');
        btn.classList.toggle('fa-times');
    }
    const btnn2 = () => {
        let btn = document.querySelector('.menu_btn');
        let navbar = document.querySelector('.oldnav');
        navbar.classList.remove('mobile');
       
    }

    const changeimg1 = () => {
        let btn = document.getElementById('arc1');
        btn.src = img2;
        btn.style.transition = "all 0.8s  ease";
        btn.style.opacity = "1";

    }
    const changeimg2 = () => {
        let btn = document.getElementById('arc1');
        btn.src = img3;
        btn.style.transition = "all 0.8s  ease";
        btn.style.opacity = "1";
    }
    const changeimg3 = () => {
        let btn = document.getElementById('arc1');
        btn.src = img4;
        btn.style.transition = "all 0.8s  ease";
        btn.style.opacity = "1";
    }
    const changeimg4 = () => {
        let btn = document.getElementById('arc1');
        btn.src = img5;
        btn.style.transition = "all 0.8s  ease";
        btn.style.opacity = "1";


    }
    const changeimg5 = () => {
        let btn = document.getElementById('arc1');
        btn.src = img1;
        btn.style.transition = "all 0.8s  ease";
        btn.style.opacity = "1";
    }
    const changeimg6 = () => {
        let btn = document.getElementById('arc1');
        btn.src = img6;
        btn.style.transition = "all 0.8s  ease";
        btn.style.opacity = "1";
    }
   
    return (
        <div>


       

            {/* <!-- <Header Start============ --> */}
            <header className='pt-5 d-lg-block d-none' id="myhead" style={{ background: 'transparent' }} >
                <nav className="header_box px-5 " >
                    <div className="logo mt-0 rounded">
                        {/* <a href="/">
                            <img className='heade_logo mx-0 ' style={{ display: 'unset' }} src={require('./images/logo.JPG')} height="100px" alt="" />
                        </a> */}
                    </div>
                    <div className="nav_icon container-fluid">

                    </div>
                    <div className="p-2  rounded-circle">
                        <img src="https://cdn.apt.london/img/shapes/white/apt-shapes-01.svg" onClick={opentog} id="myimg" className="img-fluid" width='26px' style={{ cursor: 'pointer' }} alt="" />

                    </div>
                </nav>
            </header>
            <div className="d-lg-flex justify-content-center align-items-center navcontrol  d-none " ref={newref} id="navcontrol" style={{ backgroundColor: '#000', transform: 'scale(0.0)', position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', visibility: 'hidden', opacity: '0', zIndex: "2" }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center ">
                        <div className="col-lg-4" style={{ left: '-90px', position: 'relative', bottom: '-190px' }}>
                            <img src={require('./images/tudorarchshapes/arc2.png')} className="img-fluid" style={{ opacity: '0', }} id="arc1" alt="" />
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <div className="nav_icon container-fluid text-start">
                                <div className="row flex-column">
                                    <div className="col-lg-12">
                                        <li  className="butn1"><Link onMouseOver={(e) => newref.current.style.backgroundColor = '#D6A2E8'} onMouseOut={unhover} to="/">Home</Link></li>
                                        <li  className="butn2"><Link to="/about" onMouseOver={(e) => newref.current.style.backgroundColor = '#e0d957'} onMouseOut={unhover} id="bar2" >About</Link></li>
                                        <li  className="butn3"><Link to="/gallery" onMouseOver={(e) => newref.current.style.backgroundColor = '#ff4757'} onMouseOut={unhover}  >Gallery</Link></li>
                                        <li  className="butn4"><Link to="/contact-us" onMouseOver={(e) => newref.current.style.backgroundColor = '#33d9b2'} onMouseOut={unhover}>Contact</Link></li>
                                        <li  className="butn5"><Link to="/product" onMouseOver={(e) => newref.current.style.backgroundColor = '#273c75'} onMouseOut={unhover}>Product</Link></li>
                                        {/* <li onMouseOut={unhover1} onMouseOver={changeimg6} className="butn6"><Link to="/reviews" onMouseOver={(e) => newref.current.style.backgroundColor = 'red'} onMouseOut={unhover}>Reviews</Link></li> */}

                                    </div>
                                    {/* <Link to="/login" onMouseOver={(e) => newref.current.style.backgroundColor = 'orange'} onMouseOut={unhover} className='btn btn-sm  bg-dark rounded-pill d-none d-lg-block' style={{ fontSize: '1rem' }}> <span style={{ color: "#fff" }}>Login</span></Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <header className='d-lg-none d-block' >
                <nav className="header_box px-5 " style={{ backgroundColor: '#010100' }}>
                    <div className="logo mt-5 rounded  d-lg-none d-block">
                        {/* <a href="/">
                            <img className='heade_logo mx-0 ' style={{ display: 'unset' }} src={require('./images/logo.JPG')} height="100px" alt="" />
                        </a> */}
                    </div>
                    <div className="nav_icon oldnav  container-fluid text-start">
                        <li><Link onClick={btnn2} to="/">Home</Link></li>
                        <li><Link onClick={btnn2} to="/about">About</Link></li>
                        <li><Link onClick={btnn2} to="/product">Product</Link></li>
                        <li><Link onClick={btnn2} to="/contact-us">Contact Us</Link></li>
                        {/* <li><Link onClick={btnn2} to="/service">Service</Link></li> */}
                        {/* <li><Link onClick={btnn2} to="/login" className='btn btn-sm px-5 bg-dark rounded-pill d-block d-lg-none'> <span style={{ color: "#fff" }}>Login</span></Link></li> */}


                    </div>
                    <div className="mobile_number text-white d-none d-lg-block">
                        <b><Link to="/login" style={{fontSize:'1.2rem'}} className='btn btn-sm px-5 bg-dark rounded-pill'> <span style={{ color: "#fff" }}>Login</span></Link></b>
                    </div>

                    <div className="p-2  rounded-circle">
                        <img src="https://cdn.apt.london/img/shapes/white/apt-shapes-01.svg" onClick={btnn} id="myimg" className="img-fluid" width='26px' style={{ cursor: 'pointer' }} alt="" />

                    </div>
                    {/* <i className="menu_btn fas fa-bars d-block d-lg-none text-white" onClick={btnn}></i> */}


                </nav>
            </header>
            {/* <!-- <Header End============= --> */}

        </div>
    )
}
