import React from 'react'
import { Link } from 'react-router-dom'
import { Footer } from './Footer'
import { Header } from './Header'

export const About = () => {
    return (
        <div>



            <Header />
            <section className="about_banner d-flex justify-content-start align-items-center " style={{ height: '100vh' }}>
                <div className="text-start text-white px-5">
                    <h1 style={{ fontSize: '4rem' }}><b>About Us</b></h1>
                </div>
            </section>

            <section className="aboutmain">



                <div className="aboutus py-5">
                    <div className="container py-5">
                        <div className="row justify-contet-between ">
                            <div className="col-lg-4  right_about" >

                            </div>
                            <div className="col-lg-7 left_about">
                                <div className="ms-5 d-lg-block  d-none">
                                    <h2><b>Who We are</b></h2>
                                    <p>
                                    Mahajan Group of Textiles is committed to providing constant value to our customers by providing high-Quality
                                    Textiles which make you feel connected to Indian Culture and Tradition.We constantly work on
                                    upgrading the Quality of our Textiles as per the needs of our Textile lover consumer family.
                                    </p>
                                    <a href="#" className="btn btn-dark text-white px-5">know more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about_bottom_banner my-5 " style={{ height: '70vh' }}>
                    <div className="container-fluid py-5">
                        <div className="row">
                            <div className="col-lg-6 " ></div>
                            <div className="col-lg-6" ></div>
                        </div>
                    </div>
                </div>




                {/* Category---- */}
                <section className="category mt-5">
                    <div className="container-fluid">
                        <div className="text-center">
                            <h2><b>Featured Product</b></h2>
                        </div>
                        <div className="row">
                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box4 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Poplin Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Poplin", image: 'pop3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="p-0 rounded border">
                                <div className="category_box5 d-flex justify-content-center align-items-center rounded">
                                    <h3 className="text-white"><b>Polyster Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Polyester", image: 'poly3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="p-0 border rounded">
                                <div className="category_box6 d-flex justify-content-center align-items-center rounded">

                                    <h3 className="text-white"><b>Cotton Linen Fabrics</b></h3>
                                </div>
                                <div className="d-flex justify-content-around py-4">
                                    <Link to={`/singleproject`} className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }} state={{ category: "Cotton linen", image: 'cotl3.jpg' }} ><b>View More</b></Link>
                                    <a href="tel:0000000" className="text-center btn text-white " style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff' }}  ><b>Call Now</b></a>

                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
                {/* Category---- */}

                <section className="py-5 my-5" >
                    <div className="container" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                        <div className="row">
                            <div className="col-lg-4 col-12 sideimg bg-dark">

                            </div>
                            <div className="col-lg-8 col-12 py-5">
                                <div className="text-start ">
                                    <h3>Our Journey</h3>
                                    <p>
                                        In the year 2002, the Mahajan Group of Textiles firm was established in the Bhiwandi area of Maharashtra The company headquarter is located in Mumbai. Vijaya Textiles, Mahajan Group of Textiles Textiles, and Laxmi Textiles are the three business divisions of the corporation. The company's textile sector has a distribution network in all of India's states, suitings are available in over 400 towns through 30000 retailers present in over 150 cities
                                    </p>
                                    <p>
                                        In the year 2015, the company launched a new manufacturing activity to produce indigenous linen and cotton fabrics, as well as a variety of other materials. Also, to keep up with the growing demand for linen materials.
                                    </p>
                                    <p>
                                        This is presently the largest facility of its kind. The corporation established a new Combing Division throughout the year. Then came a period of vertical integration, which made it easier to process multi-fibres and enhance technology for blended fabrics. In the year 2019, Mahajan Group of Textiles Fabrics also opened a readymade clothes plant in Bhiwandi.
                                    </p>




                                    <a href="#" className="btn  px-5 text-white" style={{ backgroundColor: '#010100' }}>Know More</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>



                {/* Founder---- */}
                {/* <section className="founder pb-5">
                    <div className="container">
                        <div className="text-center py-3" >
                            <h2>Our Founders</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6 founder_left">

                            </div>
                            <div className="col-md-6 right_text text-start py-3">
                                <div className="text-start">
                                    <h3>Ar. Vibhanshu Garg</h3>
                                    <h5>Founder (Tudor architect)</h5>
                                </div>
                                <p>
                                He is the founder of Tudor Arch Design and brings 5 years of extensive national & international 
                                architectural experience to the organization. He has worked with leading developers and organizations
                                like DLF, Arch10, ABM Consultants, Intec Infra Technologies etc. He specialized in the delivery of 
                                large-scale projects with strong command in design development, project management and site coordination
                                </p>
                            </div>
                        </div>
                        <div className="row flex-md-row flex-column-reverse">
                            <div className="col-md-6 right_text1 py-3">
                                <div className="text-start">
                                    <h3>Ar. Prerika Garg</h3>
                                    <h5>Founder (Tudor architect)</h5>
                                </div>
                                <p>
                                She is the co-founder of Tudor Arch Design. Having a masters degree in urban design, 
                                she brings 1 year and 6 months of academic experience in reputed universities and 6 months
                                of architectural experience to the organisation. She is having 2 research papers and 1 copyright on her name.
                                </p>
                            </div>
                            <div className="col-md-6 founder_left1">

                            </div>
                        </div>
                    </div>
                </section> */}
                {/* Founder---- */}



                {/* Our team--- */}
                {/* <section className="team">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-3 py-4 my-auto">
                                <div class="boxnew shadow-sm p-4">
                                    <div class="image-wrapper mb-3">
                                        <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
                                    </div>
                                    <div class="box-desc">
                                        <h5>Shubham</h5>
                                        <p>Profession</p>
                                    </div>
                                    <ul class="social">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 py-4 my-auto">
                                <div class="boxnew shadow-sm p-4">
                                    <div class="image-wrapper mb-3">
                                        <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
                                    </div>
                                    <div class="box-desc">
                                        <h5>Shubham</h5>
                                        <p>Profession</p>
                                    </div>
                                    <ul class="social">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 py-4 my-auto">
                                <div class="boxnew shadow-sm p-4">
                                    <div class="image-wrapper mb-3">
                                        <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
                                    </div>
                                    <div class="box-desc">
                                        <h5>Shubham</h5>
                                        <p>Profession</p>
                                    </div>
                                    <ul class="social">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 py-4 my-auto">
                                <div class="boxnew shadow-sm p-4">
                                    <div class="image-wrapper mb-3">
                                        <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
                                    </div>
                                    <div class="box-desc">
                                        <h5>Jon Doe</h5>
                                        <p>FrontEnd Developer</p>
                                    </div>
                                    <ul class="social">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                </section> */}
                {/* Our team--- */}


                <section className="bottom_banner text-white py-5" style={{ backgroundColor: '#010100' }}>
                    <div className="text-center">
                        <div className="logo">
                            {/* <h1 style={{ color: '#010100' }}>Mahajan Group of Textiles<span style={{ color: '#010100', fontSize: '2.3rem', opacity: '0.3' }}>.</span> </h1> */}
                            {/* <img className='bg-white foteer_img' src={bg}  alt="" /> */}
                        </div>
                        <div>
                            <h3><b>EXPERIENCE THE CHARMS OF CLASSY AND AFFORDABLE FABRICS</b></h3>
                            <p>
                            Start your journey into the world of vibrant yet stylish and high quality fabrics.
                            </p>
                            <a href="/contact-us" className="btn btn-dark text-white">Contact Us</a>
                        </div>
                    </div>
                </section>



            </section>
            <Footer />
        </div>
    )
}
